.userdetails {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: aliceblue;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.userdetails .loginbox {
  background-color: #1b1b1b;
  width: 530px;
  height: 374px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  border: 2px solid #5961F9;
  position: relative;
  z-index: 999;
}
@media screen and (max-width: 540px) {
  .userdetails .loginbox {
    width: 98%;
  }
}
@media screen and (max-width: 485px) {
  .userdetails .loginbox {
    height: 350px;
  }
}
.userdetails .loginbox .remove {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 20px;
  top: 20px;
  background-image: url('../../../res/img/quxiao2.png');
  background-repeat: no-repeat;
  /* 背景图不重复 */
  background-position: center center;
  background-size: 30px 30px;
  /* 鼠标样式 */
  cursor: pointer;
}
.userdetails .loginbox .remove:hover {
  background-image: url('../../../res/img/quxiao.png');
}
.userdetails .loginbox .imgbox {
  width: 80px;
  height: 80px;
  border-radius: 50px;
  overflow: hidden;
  margin-top: 30px;
}
.userdetails .loginbox .imgbox img {
  width: 100%;
  height: 100%;
}
.userdetails .loginbox .spanbox {
  margin-top: 10px;
}
.userdetails .loginbox .spanbox span {
  font-size: 14px;
  color: #c9c9c9;
}
.userdetails .loginbox .other {
  flex: 1;
  width: 90%;
  margin: 10px 0 0 0;
  display: flex;
  flex-direction: column;
}
.userdetails .loginbox .other .one {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #36373d;
}
.userdetails .loginbox .other .one .left {
  display: flex;
  justify-content: center;
  align-items: center;
}
.userdetails .loginbox .other .one .left .antdimgbox {
  width: 15px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}
.userdetails .loginbox .other .one .left span {
  margin-left: 8px;
  font-size: 15px;
  color: #cfcfcf;
}
.userdetails .loginbox .other .one .right span {
  font-size: 14px;
  color: #cfcfcf;
}
.userdetails .loginbox .other .two {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #36373d;
}
.userdetails .loginbox .other .two .left {
  display: flex;
  justify-content: center;
  align-items: center;
}
.userdetails .loginbox .other .two .left .antdimgbox {
  width: 15px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}
.userdetails .loginbox .other .two .left span {
  margin-left: 8px;
  font-size: 15px;
  color: #cfcfcf;
}
.userdetails .loginbox .other .two .right span {
  font-size: 14px;
  color: #cfcfcf;
}
.userdetails .loginbox .other .two .right span:nth-child(2) {
  font-size: 14px;
  color: #f7f7f7;
  /* 鼠标样式 */
  cursor: pointer;
}
.userdetails .loginbox .other .three {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.userdetails .loginbox .other .three .left {
  display: flex;
  justify-content: center;
  align-items: center;
}
.userdetails .loginbox .other .three .left .antdimgbox {
  width: 15px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}
.userdetails .loginbox .other .three .left span {
  margin-left: 8px;
  font-size: 15px;
  color: #cfcfcf;
}
.userdetails .loginbox .other .three .right span:nth-child(1) {
  color: orange;
  margin-right: 5px;
  font-size: 14px;
}
.userdetails .loginbox .other .three .right span:nth-child(2) {
  font-size: 14px;
  color: #f7f7f7;
  /* 鼠标样式 */
  cursor: pointer;
}
.userdetails .loginbox .outlogin {
  width: 120px;
  height: 35px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: #5961F9;
  color: #fff;
  border: 0px;
  /* 鼠标样式 */
  cursor: pointer;
}
