.LoadingSpinner {
  width: 100%;
  height: calc(100vh - 70px);
  height: calc(var(--vh, 1vh) * 100 - 70px);
  display: flex;
  position: relative;
}
.LoadingSpinnertwo {
  width: 100%;
  height: calc(100vh - 70px);
  height: calc(var(--vh, 1vh) * 100 - 70px);
  display: flex;
  position: relative;
}
