*{
  list-style: none;
  padding: 0;
  margin: 0;
  /* 禁止双击选中文字 */
  user-select: none;
}
.App {
  text-align: center;
  background-color:#242629;
}
@media screen and (max-width: 750px) {
  .App {
    background-color:black;
  }
}
