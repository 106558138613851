.fade-enter {
  opacity: 0;
  transform: translateY(-10px);
}
.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms ease, transform 300ms ease;
  z-index: 999;
}
.fade-exit {
  opacity: 1;
  transform: translateY(0);
  z-index: 999;
}
.fade-exit-active {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 300ms ease, transform 300ms ease;
  z-index: 999;
}