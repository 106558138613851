.rechargeissue {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: aliceblue;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.rechargeissue .loginbox {
  background-color: #1b1b1b;
  width: 780px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  position: relative;
}
@media screen and (max-width: 780px) {
  .rechargeissue .loginbox {
    width: 98%;
  }
}
.rechargeissue .loginbox .rechargeclose {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 20px;
  top: 20px;
  background-image: url('../../../res/img/quxiao2.png');
  background-repeat: no-repeat;
  /* 背景图不重复 */
  background-position: center center;
  background-size: 30px 30px;
  /* 鼠标样式 */
  cursor: pointer;
}
.rechargeissue .loginbox .rechargeclose:hover {
  background-image: url('../../../res/img/quxiao.png');
}
.rechargeissue .loginbox .onebox {
  width: 100%;
  margin-top: 25px;
}
.rechargeissue .loginbox .onebox span {
  font-size: 25px;
}
.rechargeissue .loginbox .twobox {
  width: 90%;
  height: 450px;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow-x: hidden;
  /* 隐藏水平滚动条 */
  overflow-y: scroll;
  /* 开启垂直滚动 */
  /* 为了兼容 Firefox */
  scrollbar-color: rgba(80, 80, 80, 0.5) transparent;
  /* 滑块颜色和轨道颜色 */
}
.rechargeissue .loginbox .twobox::-webkit-scrollbar {
  width: 10px;
  /* 设置垂直滚动条的宽度 */
  height: 8px;
  /* 设置水平滚动条的高度 */
}
.rechargeissue .loginbox .twobox::-webkit-scrollbar-track {
  background: transparent;
  /* 隐藏轨道 */
}
.rechargeissue .loginbox .twobox::-webkit-scrollbar-thumb {
  scrollbar-color: rgba(80, 80, 80, 0.5) transparent;
  /* 滑块颜色和轨道颜色 */
  border-radius: 4px;
  /* 滑块的圆角 */
}
.rechargeissue .loginbox .twobox .issuespanbox {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #2c4636;
  margin-top: 10px;
  padding: 10px 10px;
  border-radius: 10px;
}
.rechargeissue .loginbox .twobox .issuespanbox:nth-child(1) {
  margin-top: 0px;
}
.rechargeissue .loginbox .twobox .issuespanbox span:nth-child(1) {
  display: flex;
  font-size: 19px;
  text-align: left;
}
.rechargeissue .loginbox .twobox .issuespanbox span:nth-child(2) {
  font-size: 15px;
  margin-top: 5px;
  display: flex;
  text-align: left;
}
