.login {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: aliceblue;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.login .loginbox {
  background-color: #1b1b1b;
  width: 530px;
  height: 374px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  border: 2px solid #5961F9;
  position: relative;
  z-index: 999;
}
@media screen and (max-width: 540px) {
  .login .loginbox {
    width: 98%;
  }
}
@media screen and (max-width: 485px) {
  .login .loginbox {
    height: 350px;
  }
}
.login .loginbox .remove {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 20px;
  top: 20px;
  background-image: url('../../../res/img/quxiao2.png');
  background-repeat: no-repeat;
  /* 背景图不重复 */
  background-position: center center;
  background-size: 30px 30px;
  /* 鼠标样式 */
  cursor: pointer;
}
.login .loginbox .remove:hover {
  background-image: url('../../../res/img/quxiao.png');
}
.login .loginbox .textone {
  font-size: 36px;
  margin-top: 30px;
  background-image: linear-gradient(to left, #59f97c, #d6f959);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
  /* 需要对于老版本的WebKit浏览器 */
}
@media screen and (max-width: 485px) {
  .login .loginbox .textone {
    font-size: 34px;
  }
}
.login .loginbox .texttwo {
  font-size: 15px;
  margin-top: 10px;
  color: #525252;
}
.login .loginbox .email {
  width: 80%;
  height: 50px;
  background-color: #353535;
  margin-top: 10px;
  display: flex;
  align-items: center;
  border-radius: 15px;
  overflow: hidden;
}
@media screen and (max-width: 485px) {
  .login .loginbox .email {
    width: 360px;
  }
}
.login .loginbox .email input:-webkit-autofill,
.login .loginbox .email input:-webkit-autofill:hover,
.login .loginbox .email input:-webkit-autofill:focus,
.login .loginbox .email input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #353535 inset !important;
  -webkit-text-fill-color: #fff !important;
}
.login .loginbox .email input:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0 30px #444444 inset !important;
  /* 鼠标悬停时的背景颜色 */
}
.login .loginbox .email:hover {
  background-color: #444444;
}
.login .loginbox .email:hover input {
  background-color: #444444;
}
.login .loginbox .email .imgbox {
  width: 25px;
  height: 25px;
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login .loginbox .email input {
  flex: 1;
  height: 100%;
  border: 0;
  /*清除自带的2px的边框*/
  padding: 0;
  /*清除自带的padding间距*/
  outline: none;
  /*清除input点击之后的黑色边框*/
  background-color: #353535;
  color: #fff;
  margin-right: 10px;
}
.login .loginbox .emailcode {
  width: 80%;
  height: 50px;
  background-color: #353535;
  margin-top: 10px;
  display: flex;
  align-items: center;
  border-radius: 15px;
  overflow: hidden;
}
@media screen and (max-width: 485px) {
  .login .loginbox .emailcode {
    width: 360px;
  }
}
.login .loginbox .emailcode input:-webkit-autofill,
.login .loginbox .emailcode input:-webkit-autofill:hover,
.login .loginbox .emailcode input:-webkit-autofill:focus,
.login .loginbox .emailcode input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #353535 inset !important;
  -webkit-text-fill-color: #fff !important;
}
.login .loginbox .emailcode input:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0 30px #444444 inset !important;
  /* 鼠标悬停时的背景颜色 */
}
.login .loginbox .emailcode:hover {
  background-color: #444444;
}
.login .loginbox .emailcode:hover input {
  background-color: #444444;
}
.login .loginbox .emailcode .imgbox {
  width: 25px;
  height: 25px;
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login .loginbox .emailcode input {
  flex: 1;
  height: 100%;
  border: 0;
  /*清除自带的2px的边框*/
  padding: 0;
  /*清除自带的padding间距*/
  outline: none;
  /*清除input点击之后的黑色边框*/
  background-color: #353535;
  color: #fff;
  margin-right: 10px;
}
.login .loginbox .emailcode .send {
  margin-right: 10px;
  /* 鼠标样式 */
  cursor: pointer;
}
.login .loginbox .emailcode .send :hover {
  color: #c3fc25;
}
.login .loginbox .emailcode .send span {
  color: #5961F9;
}
.login .loginbox .emailcode .countdown {
  margin-right: 30px;
}
.login .loginbox .emailcode .countdown span {
  font-size: 18px;
  color: #5961F9;
}
.login .loginbox .tologin {
  width: 200px;
  height: 50px;
  background-image: linear-gradient(to bottom, #5961F9, #5961F9);
  border-radius: 15px;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* 鼠标样式 */
  cursor: pointer;
}
.login .loginbox .tologin:hover {
  background-image: linear-gradient(to left, #59f97c, #d6f959);
}
.login .loginbox .tologin span {
  font-size: 20px;
}
.login .loginbox .agreement {
  margin-top: 15px;
  display: flex;
}
@media screen and (max-width: 485px) {
  .login .loginbox .agreement {
    font-size: 13px;
  }
}
.login .loginbox .agreement span:nth-child(2),
.login .loginbox .agreement span:nth-child(4) {
  color: #5961F9;
  /* 鼠标样式 */
  cursor: pointer;
}
.login .loginbox .agreement span:nth-child(2):hover,
.login .loginbox .agreement span:nth-child(4):hover {
  color: #2530fc;
}
.login .logintishihe {
  position: fixed;
  left: 50%;
  /* 水平居中 */
  top: 50%;
  /* 垂直居中 */
  transform: translate(-50%, -50%) translateY(-100px);
  /* 水平和垂直方向上平移自身尺寸的一半，再向上偏移100px */
  width: 150px;
  height: 40px;
  background-color: #682bd8;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  z-index: 99999;
}
