.topbar {
  display: flex;
  align-items: center;
  height: 70px;
  width: 100%;
  background-color: black;
}
@media screen and (max-width: 750px) {
  .topbar {
    border-bottom: 2px solid transparent;
    /* 先设置一个透明的边框 */
    border-image: linear-gradient(to right, black, #2B2D31, black) 1;
    /* 使用渐变作为边框 */
  }
}
.topbar .left {
  flex: 1;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 470px) {
  .topbar .left {
    display: flex;
  }
}
.topbar .left img {
  margin-left: 30px;
  width: 45px;
  height: 45px;
}
@media screen and (max-width: 480px) {
  .topbar .left img {
    width: 130px;
    margin-left: 28px;
    width: 35px;
    height: 35px;
  }
}
@media screen and (max-width: 435px) {
  .topbar .left img {
    width: 130px;
    margin-left: 18px;
    width: 35px;
    height: 35px;
  }
}
.topbar .left h1 {
  font-size: 26px;
  background-image: linear-gradient(to right, #5961F9, indigo);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
  /* 需要对于老版本的WebKit浏览器 */
}
@media screen and (max-width: 580px) {
  .topbar .left h1 {
    font-size: 22px;
  }
}
@media screen and (max-width: 530px) {
  .topbar .left h1 {
    font-size: 20px;
  }
}
@media screen and (max-width: 505px) {
  .topbar .left h1 {
    font-size: 18px;
  }
}
@media screen and (max-width: 470px) {
  .topbar .left h1 {
    font-size: 18px;
  }
}
@media screen and (max-width: 402px) {
  .topbar .left h1 {
    font-size: 16px;
  }
}
@media screen and (max-width: 377px) {
  .topbar .left h1 {
    font-size: 15px;
  }
}
.topbar .middle {
  flex: 2;
  color: aliceblue;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.topbar .middle .middleinbox {
  display: flex;
  align-items: center;
  /* 鼠标样式 */
  cursor: pointer;
}
.topbar .middle .middleinbox .imghe {
  width: 30px;
  height: 30px;
  background-color: #2f3036;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.topbar .middle .middleinbox .imghe.one {
  background-color: lightgreen;
}
.topbar .middle .middleinbox .imghe.two {
  background-color: #2f3036;
}
.topbar .middle .middleinbox .imghe img {
  width: 60%;
  height: 60%;
}
.topbar .middle .middleinbox h2 {
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
}
@media screen and (max-width: 750px) {
  .topbar .middle .middleinbox h2 {
    display: none;
  }
}
.topbar .right {
  flex: 1.5;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media screen and (max-width: 950px) {
  .topbar .right {
    flex: 0.5;
  }
}
.topbar .right .one {
  display: flex;
  align-items: center;
  margin-right: 20px;
  /* 鼠标样式 */
  cursor: pointer;
  background-color: black;
  border: none;
}
@media screen and (max-width: 950px) {
  .topbar .right .one {
    display: none;
  }
}
.topbar .right .one .imghe {
  width: 20px;
  height: 20px;
}
.topbar .right .one .imghe img {
  width: 100%;
  height: 100%;
}
.topbar .right .one span {
  font-size: 13px;
  color: #ffffff;
}
.topbar .right .two {
  display: flex;
  align-items: center;
  margin-right: 20px;
  background-color: black;
  border: none;
  /* 鼠标样式 */
  cursor: pointer;
}
@media screen and (max-width: 950px) {
  .topbar .right .two {
    display: none;
  }
}
.topbar .right .two .imghe {
  width: 20px;
  height: 20px;
}
.topbar .right .two .imghe img {
  width: 100%;
  height: 100%;
}
.topbar .right .two span {
  font-size: 13px;
  color: #ffffff;
}
.topbar .right .threetwo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
  /* 鼠标样式 */
  cursor: pointer;
}
.topbar .right .threetwo .imghe {
  width: 20px;
  height: 20px;
  border-radius: 6px;
  overflow: hidden;
}
.topbar .right .threetwo .imghe img {
  width: 100%;
  height: 100%;
}
.topbar .right .threetwo span {
  font-size: 13px;
  color: #ffffff;
}
.topbar .right .three {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
  /* 鼠标样式 */
  cursor: pointer;
}
.topbar .right .three .imghe {
  width: 20px;
  height: 20px;
}
.topbar .right .three .imghe img {
  width: 100%;
  height: 100%;
}
.topbar .right .three span {
  font-size: 13px;
  color: #ffffff;
}
