.tishihe {
  position: fixed;
  top: 100px;
  right: 50px;
  width: 150px;
  height: 40px;
  background-color: #5961F9;
  border-radius: 25px 0 25px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  z-index: 99999;
}
.tishihetwo {
  position: fixed;
  top: 100px;
  right: 80px;
  width: 200px;
  height: 250px;
  background-image: linear-gradient(to left, #7b5adf, #586af0);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  z-index: 99999;
}
.tishihetwo img {
  width: 100px;
  height: 100px;
  margin-top: 20px;
}
.tishihetwo span:nth-child(2) {
  width: 100%;
  text-align: left;
  margin-left: 40px;
  margin-top: 10px;
  font-size: 13px;
}
.tishihetwo span:nth-child(3) {
  margin-top: 2px;
  font-size: 13px;
}
.tishihetwo span:nth-child(4) {
  width: 100%;
  text-align: left;
  padding: 0 20px;
  margin-top: 10px;
  font-size: 12px;
  box-sizing: border-box;
}
