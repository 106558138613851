/* 进入时透明度渐显 */
.fade-enter {
    opacity: 0;
    overflow: hidden; /* 禁止滚动 */
  }
  
  .fade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease;
  }
  
  /* 离开时透明度渐隐 */
  .fade-exit {
    opacity: 1;
    overflow: hidden; /* 禁止滚动 */
  }
  
  .fade-exit-active {
    opacity: 0;
    transition: opacity 500ms ease;
  }
  
  /* 全局样式：避免滚动条闪现时的抖动 */
  html, body {
    height: 100%;
    overflow-y: hidden; /* 临时禁用滚动条 */
  }