.recharge {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: aliceblue;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9998;
}
.recharge .loginbox {
  background-color: #1b1b1b;
  width: 780px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  position: relative;
}
.recharge .loginbox .promptbox {
  padding: 5px 20px;
  background-color: #242629;
  border: 1px solid #8a8888;
  position: absolute;
  top: 128px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.recharge .loginbox .promptbox span {
  color: #ffffff;
}
@media screen and (max-width: 540px) {
  .recharge .loginbox {
    width: 98%;
  }
}
.recharge .loginbox .rechargeclose {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 20px;
  top: 20px;
  background-image: url('../../../res/img/quxiao2.png');
  background-repeat: no-repeat;
  /* 背景图不重复 */
  background-position: center center;
  background-size: 30px 30px;
  /* 鼠标样式 */
  cursor: pointer;
}
.recharge .loginbox .rechargeclose:hover {
  background-image: url('../../../res/img/quxiao.png');
}
.recharge .loginbox .onebox {
  width: 100%;
  margin-top: 25px;
}
.recharge .loginbox .onebox span {
  font-size: 25px;
  background: linear-gradient(to right, #ff7e5f, #FFCB7D);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.recharge .loginbox .twobox {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  position: relative;
  /* 滑动背景 */
}
.recharge .loginbox .twobox .slider {
  position: absolute;
  height: 40px;
  width: 200px;
  /* 等于单个 box 的宽度 */
  background-image: linear-gradient(to left, #499eee, #f0e158);
  border-radius: 10px;
  transition: transform 0.3s ease;
  z-index: 0;
  /* 根据状态移动背景 */
}
@media screen and (max-width: 485px) {
  .recharge .loginbox .twobox .slider {
    width: 200px;
  }
}
.recharge .loginbox .twobox .slider.to-left {
  transform: translateX(-50%);
}
.recharge .loginbox .twobox .slider.to-right {
  transform: translateX(50%);
}
.recharge .loginbox .twobox .userselect {
  width: 200px;
  height: 40px;
  background-color: #302f2f;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
}
.recharge .loginbox .twobox .userselect span {
  font-size: 18px;
  z-index: 1;
}
.recharge .loginbox .threebox {
  width: 100%;
  height: 450px;
  margin: 20px 0 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow-x: hidden;
  /* 隐藏水平滚动条 */
  overflow-y: scroll;
  /* 开启垂直滚动 */
  scrollbar-width: none;
  /* 针对Firefox */
  -ms-overflow-style: none;
  /* 针对IE和Edge */
  /* 针对Chrome, Safari和Opera */
}
.recharge .loginbox .threebox::-webkit-scrollbar {
  display: none;
}
.recharge .loginbox .threebox .threepaddingboxin {
  flex: 1;
  min-width: 230px;
  max-width: 280px;
  height: 100%;
  /* 设置盒子的高度，可以根据需要修改 */
  margin: 0 10px;
  padding-bottom: 20px;
  box-sizing: border-box;
}
.recharge .loginbox .threebox .threepaddingboxin .threeboxin {
  width: 100%;
  height: 100%;
  border: 1px solid #858584;
  display: flex;
  flex-direction: column;
  background-color: #302f2f;
  box-sizing: border-box;
}
.recharge .loginbox .threebox .threepaddingboxin .threeboxin .one {
  margin-top: 10px;
}
.recharge .loginbox .threebox .threepaddingboxin .threeboxin .one span {
  font-size: 21px;
  color: #f0e158;
}
.recharge .loginbox .threebox .threepaddingboxin .threeboxin .two {
  margin-top: 5px;
}
.recharge .loginbox .threebox .threepaddingboxin .threeboxin .two span {
  font-size: 18px;
}
.recharge .loginbox .threebox .threepaddingboxin .threeboxin .three span {
  font-size: 13px;
}
.recharge .loginbox .threebox .threepaddingboxin .threeboxin .four {
  width: 75%;
  height: 40px;
  font-size: 14px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #586af0;
  border-radius: 8px;
  margin: 10px auto;
  cursor: pointer;
}
.recharge .loginbox .threebox .threepaddingboxin .threeboxin .four span {
  font-size: 16px;
}
.recharge .loginbox .threebox .threepaddingboxin .threeboxin .five {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.recharge .loginbox .threebox .threepaddingboxin .threeboxin .five .spanbox {
  width: 80%;
  height: 40px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #6b6b6b;
}
.recharge .loginbox .threebox .threepaddingboxin .threeboxin .five .spanbox span:nth-child(1) {
  color: #4eca8c;
}
.recharge .loginbox .threebox .threepaddingboxin .threeboxin .five .spanbox span:nth-child(2) {
  margin-left: 10px;
  font-size: 14px;
}
.recharge .loginbox .threebox .threepaddingboxin .threeboxin .six {
  width: 200px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3b3a3a;
  border-radius: 5px;
  margin: 10px auto;
  cursor: pointer;
}
.recharge .loginbox .userpayment {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}
.recharge .loginbox .userpayment .userpaymentin {
  width: 600px;
  height: 390px;
  padding-top: 40px;
  border-radius: 30px;
  background-color: #1b1b1b;
  position: relative;
}
.recharge .loginbox .userpayment .userpaymentin .userpaymentclose {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 20px;
  top: 20px;
  background-image: url('../../../res/img/quxiao2.png');
  background-repeat: no-repeat;
  /* 背景图不重复 */
  background-position: center center;
  background-size: 30px 30px;
  /* 鼠标样式 */
  cursor: pointer;
}
.recharge .loginbox .userpayment .userpaymentin .userpaymentclose:hover {
  background-image: url('../../../res/img/quxiao.png');
}
.recharge .loginbox .userpayment .userpaymentin .userpaybox {
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  margin-left: 50px;
  color: #ccc;
}
.recharge .loginbox .userpayment .userpaymentin .userpaybox .payway {
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 3px 5px;
  cursor: pointer;
  margin-right: 5px;
}
.recharge .loginbox .userpayment .userpaymentin .userpaybox .payway.down {
  border: 1px solid orange;
}
.recharge .loginbox .userpayment .userpaymentin .userpaybox .payway span {
  margin-left: 3px;
}
.recharge .loginbox .userpayment .userpaymentin .erweima {
  margin-top: 20px;
  width: 100%;
  height: 150px;
}
.recharge .loginbox .userpayment .userpaymentin .erweima .imgbox {
  height: 150px;
  width: 150px;
  margin: 0 auto;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.recharge .loginbox .userpayment .userpaymentin .erweima .imgbox img {
  width: 100%;
  height: 100%;
}
.recharge .loginbox .userpayment .userpaymentin .erweimatext {
  margin-top: 3px;
}
.recharge .loginbox .userpayment .userpaymentin .erweimatext span {
  font-size: 14px;
}
.recharge .loginbox .userpayment .userpaymentin .userpaybutton {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: end;
  margin-top: 20px;
}
.recharge .loginbox .userpayment .userpaymentin .userpaybutton button {
  all: unset;
  height: 100%;
  width: 75px;
  border-radius: 6px;
  margin: 0 5px;
  font-size: 14px;
  cursor: pointer;
}
.recharge .loginbox .userpayment .userpaymentin .userpaybutton button:nth-child(1) {
  background-color: orange;
  border: 1px solid orange;
}
.recharge .loginbox .userpayment .userpaymentin .userpaybutton button:nth-child(2) {
  margin-right: 25px;
  border: 1px solid #666666;
}
